import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { HiBadgeCheck } from 'react-icons/hi';
import tw from 'twin.macro';

import Loading from '../images/loading.png';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import { Button } from '@chakra-ui/react';

const Section = tw.section`w-screen h-screen bg-gray-200 flex flex-col justify-center items-center text-center`;

const Title = tw.h1`font-display font-bold text-2xl mb-2`;

const HiBadgeCheckContainer = tw(HiBadgeCheck)`mb-2 text-green-600`;

const HANDOVER_ORDER = gql`
  mutation HANDOVER_ORDER($orderId: String!, $packageId: String!) {
    handoverPackages(orderId: $orderId, status: "DONE", packageId: $packageId) {
      id
    }
  }
`;
const ORDER = gql`
  query ORDER($orderId: String!) {
    orderTest(orderId: $orderId) {
      id
      packages {
        id
      }
    }
  }
`;

const HandoverPage = ({ data: { Postbote } }) => {
  const [status, setStatus] = useState('LOADING');
  const [orderId, setOrderId] = useState('');
  const { data, loading, error } = useQuery(ORDER, {
    variables: {
      orderId,
    },
    skip: !orderId,
  });
  const [handover] = useMutation(HANDOVER_ORDER);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('orderId');

    if (orderId) {
      setOrderId(orderId);
      setStatus('ID_PROVIDED');
    } else {
      setStatus('NO_ID_PROVIDED');
    }
  }, []);

  const handoverPackage = async () => {
    try {
      if (data && data.orderTest && data.orderTest.packages[0]) {
        await handover({
          variables: {
            orderId,
            packageId: data.orderTest.packages[0].id,
          },
        });
        setStatus('HANDED_OVER');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'LOADING' || loading) {
    return (
      <PageLayout>
        <SEO title="Abholer wird verifiziert " />
        <Section>
          <p>Der Abholer wird verifiziert.</p>
          <div className="mt-8">
            <img src={Loading} className="h-10 spin-animation" alt="Loading Spinner" />
          </div>
        </Section>
      </PageLayout>
    );
  }

  if (status === 'NO_ID_PROVIDED' || error) {
    return (
      <PageLayout>
        <SEO title="Link fehlerhaft" />
        <Section>
          <p>Dieser Link ist abgelaufen oder du hast einen fehlerhaften Link erhalten.</p>
        </Section>
      </PageLayout>
    );
  }
  if (status === 'HANDED_OVER') {
    return (
      <PageLayout>
        <SEO title="Übergabe erfolgreich" />

        <Section>
          <HiBadgeCheckContainer size="100" />
          <Title>Übergabe erfolgreich </Title>
        </Section>
      </PageLayout>
    );
  }
  if (data && data.orderTest && data.orderTest.packages.length === 0) {
    return (
      <PageLayout>
        <SEO title="Sendung noch nicht angenommen" />
        <Section>
          <p>Es wurde noch keine Sendung angenommen.</p>
        </Section>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <SEO title="Abholer verifiziert" />

      <Section>
        <HiBadgeCheckContainer size="100" />
        <Title>Abholer erfolgreich verifiziert</Title>
        <p className="mb-4">Du kannst jetzt das Paket übergeben und hier bestätigen.</p>
        <Button variantColor="green" onClick={handoverPackage}>
          Übergabe bestätigen.
        </Button>
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default HandoverPage;
